import * as React from 'react';
import SEO from '../components/atoms/SEO';
import { Box, Typography } from '@material-ui/core';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

export default function TermsPage() {
  useAnalyticsSessionData();

  return (
    <GenericPage>
      <SEO title="Terms and conditions" />
      <Box>
        <Box p="6% 0% 1% 10%" width="100%" alignItems="left">
          <Typography variant="h4">Terms and conditions</Typography>
          <p>
            <em>Last updated 27 April 2020</em>
          </p>
        </Box>
        <Box p="1% 10% 3% 10%" width="100%">
          <p>
            These terms of use (together with the documents referred to in them)
            tell you the terms of use on which you may use or access
            www.buildtorent.io, a subdomain or any such related websites and/or
            mobile application for such website (our “Sites“) whether as a guest
            or registered user. Use of our Sites includes accessing, browsing or
            registering for an account.
          </p>
          <p>
            By using our Sites, you confirm that you accept the Terms and that
            you agree to comply with them.
          </p>
          <p>
            If you do not agree to these terms of use, you must not use our
            Sites.
          </p>
          <p>
            <strong>1. Other Applicable Terms</strong>
          </p>
          <p>
            These terms of use refer to the following additional terms, which
            also apply to your use of our Sites:
          </p>
          <p>
            Our <a href="/privacy">Privacy Policy</a>, which sets out the terms
            on which we process any personal data we collect from you, or that
            you provide to us.
          </p>
          <p>
            Our <a href="/acceptable-use">Acceptable Use Policy</a>, which sets
            out the permitted uses and prohibited uses of our Sites. When using
            our Sites, you must comply with this{' '}
            <a href="/terms/acceptable-use">Acceptable Use Policy</a>.
          </p>
          <p>
            Our <a href="/cookies">Cookie Policy</a>, which sets out information
            about the cookies on our Sites.
          </p>
          <p>
            <strong>2. Information about us</strong>
          </p>
          <p>
            Our Sites are operated by Build To Rent (“B2Rent Ltd”, “We” or
            “us“). Our office is located at 63/66 Hatton Garden, Fifth Floor,
            Suite 23, London EC1N 8LE.
          </p>
          <p>
            <strong>3. Accessing our Sites</strong>
          </p>
          <p>
            We do not guarantee that our Sites, or any content on them, will
            always be available or be uninterrupted. Access to our Sites is
            permitted on a temporary basis. We may suspend, withdraw,
            discontinue or change all or any part of our Sites without notice.
            We will not be liable to you if for any reason our Sites are
            unavailable at any time or for any period.
          </p>
          <p>
            You are responsible for making all arrangements necessary for you to
            have access to our Sites.
          </p>

          <p>
            <strong>4. Your account and password</strong>
          </p>
          <p>
            In order to register for an account on our Sites you must be aged 18
            or over at the point of registration. You must have the power to
            enter a binding contract with us and not be barred from doing so
            under any applicable laws.{' '}
          </p>
          <p>
            If you choose, or you are provided with, any user identification
            code, password or any other piece of information as part of our
            security procedures to set up an account, you must treat such
            information as confidential. You must not disclose it to any third
            party.{' '}
          </p>
          <p>
            We have the right to disable any user identification code or
            password, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion you have failed to comply with applicable
            law or any of the provisions of these terms of use and/or if we
            believe that your account is being used in an unauthorised or
            fraudulent manner.{' '}
          </p>
          <p>
            If you know or suspect that anyone other than you knows your user
            identification code or password you must promptly notify us at
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
            Following such notification you may be required to set up a new
            account with a new identification code and/or password.
          </p>
          <p>
            <strong>5. Agreements between Users of Our Sites</strong>
          </p>
          <p>
            Our Sites allow property owners and managers to advertise their
            properties (each, an “Advertiser“) to potential tenants (each, a
            “Tenant“).{' '}
          </p>
          <p>
            You may use the Sites as a guest user or a registered user. Once you
            have discovered a property that best suits your needs, you can sign
            the rental contract and provide information for reference check. You
            will not be charged and the contract will not be valid until
            reference check is completed.
          </p>
          <p>
            We do not own or manage, nor do we contract for, any rental property
            listed on our Sites. We will not be a party to any agreement between
            an Advertiser and a Tenant. The terms of any agreement entered into
            between an Advertiser and a Tenant may vary from Advertiser to
            Advertiser. It is your responsibility to review and agree to an
            Advertiser’s specific terms including the Advertiser’s terms
            relating to payments and cancellations where payment is made via our
            Sites. All aspects of a transaction between a Tenant and an
            Advertiser, including (but not limited to) the quality, condition,
            safety or legality of the properties advertised and the ability of a
            user to enter into a transaction are solely the responsibility of
            each user. This includes the terms of any security deposit, which
            are set by the Advertiser. We do not represent, or negotiate, or
            carry out research on the part of or act on behalf of either
            Advertisers or Tenants.
          </p>
          <p>
            We do not accept any responsibility for the confirmation of a Tenant
            and/or Advertiser’s identity. We encourage users to take all such
            steps as necessary to communicate directly with a Tenant/Advertiser
            (as applicable) to assure yourself of the other person’s identity,
            details of the property and any tenancy agreement.
          </p>
          <p>
            <strong>6. Prices</strong>
          </p>
          <p>
            The prices of properties displayed on the Sites are liable to change
            at any time. Despite our best efforts, some of the prices listed on
            the Sites may be incorrect. We expressly reserve the right to
            correct any pricing errors on our Sites and/or on potential bookings
            which have not yet been completed.
          </p>
          <p>
            We display the prices that Advertisers provide to us from time to
            time. We are not responsible or liable for the accuracy of the
            prices displayed, to the maximum extent permitted by applicable law.
          </p>

          <p>
            <strong>7. Payments</strong>
            <br />
            In some circumstances, you can make payments to Advertisers via our
            Sites using payment providers. In such circumstances, it remains
            your responsibility to make yourself aware of the Advertiser’s
            booking and cancellation policies. Any deposit paid to an Advertiser
            via our Sites is held by buildtorent.io until the start of the
            tenancy agreement, from which it will be transferred to and held by
            the Advertiser. At the end of a tenancy agreement, you must contact
            the Advertiser to obtain the return of your deposit.
          </p>
          <p>
            <strong>8. Changes and cancellation</strong>
          </p>
          <p>
            Any tenancy agreement entered into will be between a Tenant and an
            Advertiser. It is a Tenant’s responsibility to make themselves aware
            of the Advertiser’s cancellation policy at the time of booking. In
            the event that you need to change or cancel your agreement, please
            contact us at{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
          </p>
          <p>
            <strong>9. Intellectual property rights</strong>
          </p>
          <p>
            We are the owner or the licensee of all intellectual property rights
            in our Sites, and in the material published on it. Those works are
            protected by copyright laws and treaties (and/or similar
            intellectual property laws, as relevant) around the world. All such
            rights are reserved.
          </p>
          <p>
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </p>
          <p>
            Our status (and that of any identified contributors) as the authors
            of content on our Sites must always be acknowledged.
          </p>
          <p>
            You must not use any part of the content on our Sites for commercial
            purposes without obtaining a licence to do so from us.
          </p>
          <p>
            If you print off, copy or download any part of our Sites in breach
            of these terms of use, your right to use our Sites will cease
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made.
          </p>
          <p>
            <strong>10. No reliance on information</strong>
          </p>
          <p>
            The content of our Sites is provided for general information only.
            It is not intended to amount to advice on which you should rely. You
            must obtain professional or specialist advice before taking, or
            refraining from, any action on the basis of the content on our
            Sites, including entering into a tenancy agreement for a property
            advertised on our Sites.
          </p>
          <p>
            We have no duty to pre-screen content posted on our Sites by users
            whether directly contributed by the user or contributed by us or a
            third party on behalf of the user (including, without limitation,
            property listings, reviews of a property, participation in an
            interactive community, forum or blog or any other content provided
            by a user to our Sites).
          </p>
          <p>
            Although we make reasonable efforts to update the information on our
            Sites, we make no representations, warranties or guarantees, whether
            express or implied, that the content on our Sites is accurate,
            complete or up-to-date. We display the property information that
            Advertisers provide to us from time to time. To the maximum extent
            permitted by applicable law, we are not responsible for the accuracy
            of the property information displayed.
          </p>
          <p>
            We make no representations about the suitability of the information
            and services available on our Sites for any purpose and the
            inclusion of any properties on our Sites does not constitute any
            endorsement or recommendation of such properties by us.
          </p>
          <p>
            <strong>11. Limitation of our Liability</strong>
          </p>
          <p>
            Nothing in these terms of use excludes or limits our liability for
            death or personal injury arising from our negligence, or our fraud
            or fraudulent misrepresentation, or any other liability that cannot
            be excluded or limited by applicable law as set out in clause 17.{' '}
          </p>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            conditions, warranties, representations or other terms which may
            apply to our Sites or any content on it, whether express or implied.
          </p>
          <p>
            We will not be liable to any user for any loss or damage, whether in
            contract, tort (including negligence), breach of statutory duty, or
            otherwise, even if foreseeable, arising under or in connection with:
          </p>
          <ul>
            <li>use of, or inability to use, our Sites;</li>
            <li>the accuracy of any property listings; and/or</li>
            <li>use of or reliance on any content displayed on our Sites.</li>
          </ul>
          <p>
            If you are a business user, please note that in particular, we will
            not be liable for:
          </p>
          <ul>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>
              loss of business opportunity, goodwill or reputation; and/or
            </li>
            <li>any indirect or consequential loss or damage.</li>
          </ul>
          <p>
            If you are a consumer user, please note that we only provide our
            Sites for domestic and private use. You agree not to use our Sites
            for any commercial or business purposes, and we have no liability to
            you for any loss of profit, loss of business, business interruption,
            and/ or loss of business opportunity.
          </p>
          <p>
            To the maximum extent permitted by applicable law, we will not be
            liable for any loss or damage caused by a virus, distributed
            denial-of-service attack, or other technologically harmful material
            that may infect your computer equipment, computer programs, data or
            other proprietary material due to your use of our Sites or to your
            downloading of any content on it, or on any website linked to it.
          </p>
          <p>
            We assume no responsibility for the content of websites linked on
            our Sites. Such links should not be interpreted as endorsement by us
            of those linked websites. We will not be liable for any loss or
            damage that may arise from your use of them.
          </p>
          <p>
            We will not be liable for any act, error, omission, representations,
            warranties, breach or negligence by or of any third party, including
            an Advertiser or a Student.
          </p>

          <p>
            <strong>12. Uploading content to Our Sites</strong>
          </p>
          <p>
            Whenever you make use of a feature that allows you to upload content
            to our Sites, or to make contact with other users of our Sites, you
            must comply with the content standards set out in our{' '}
            <a href="/terms/acceptable-use">Acceptable Use Policy</a>.
          </p>
          <p>
            You warrant that any such contribution does comply with the{' '}
            <a href="/terms/acceptable-use">Acceptable Use Policy</a>, and you
            will be liable to us and indemnify us for any breach of that
            warranty. This means you will be responsible for any loss or damage
            we suffer as a result of your breach of warranty.
          </p>
          <p>
            Any content you upload to our Sites or provide to us in any other
            way for us to use on our Sites will be considered non-confidential
            and non-proprietary. You retain all of your ownership rights in your
            content, but are required to grant us and other users of our Sites a
            royalty-free, worldwide licence to use, store and copy that content
            and to distribute and make it available to third parties.
          </p>
          <p>
            We also have the right to disclose your identity to any third party
            who can reasonably demonstrate that any content posted or uploaded
            by you to our Sites constitutes a violation of their intellectual
            property rights, or of their right to privacy.
          </p>
          <p>
            We will not be responsible, or liable to any third party, for the
            content or accuracy of any content or listings posted by you or any
            other user.
          </p>
          <p>
            We have the right to remove any posting you make on our Sites if, in
            our opinion, your post does not comply with the content standards
            set out in our{' '}
            <a href="/terms/acceptable-use">Acceptable Use Policy</a>.
          </p>
          <p>
            The views expressed by other users do not represent our views or
            values.
          </p>
          <p>
            <strong>13. Viruses</strong>
          </p>
          <p>
            We do not guarantee that our Sites will be secure or free from bugs
            or viruses. You are responsible for configuring your information
            technology, computer programmes and platform in order to access our
            Sites. You should use your own virus protection software.
          </p>
          <p>
            You must not misuse our Sites by knowingly introducing viruses,
            trojans, worms, logic bombs or other material which is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our Sites, the server on which our Sites are stored or any
            server, computer or database connected to our Sites. You must not
            attack our Site via a denial-of-service attack or a distributed
            denial-of service attack. By breaching this provision, you could
            commit a criminal offence under applicable cyber law. We will report
            any such breach to the relevant law enforcement authorities and we
            will co-operate with those authorities by disclosing your identity
            to them. In the event of such a breach, your right to use our Sites
            will cease immediately.
          </p>
          <p>
            <strong>14. Linking to our Sites</strong>
          </p>
          <p>
            You may link to our home pages, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it.
          </p>
          <p>
            You must not establish a link in such a way as to suggest any form
            of association, approval or endorsement on our part where none
            exists.
          </p>
          <p>
            You must not establish a link to our Sites in any website that is
            not owned by you.
          </p>
          <p>
            Our Sites must not be framed on any other site, nor may you create a
            link to any part of our Sites other than the home pages.
          </p>
          <p>
            We reserve the right to withdraw linking permission without notice.
          </p>
          <p>
            The website in which you are linking must comply in all respects
            with the content standards set out in our{' '}
            <a href="/terms/acceptable-use">Acceptable Use Policy</a>.
          </p>
          <p>
            If you wish to make any use of content on our Sites other than that
            set out above, please contact{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
          </p>
          <p>
            <strong>15. Third party links and resources on our Sites</strong>
          </p>
          <p>
            Where our Sites contain links to other sites and resources provided
            by third parties, these links are provided for your information
            only.
          </p>
          <p>
            Any maps provided on our Sites that are provided by Google are
            subject to the current terms and conditions published by Google
            available at:
          </p>
          <p>
            http://www.google.com/intl/en/help/terms_maps.html and
            https://developers.google.com/maps/terms.
          </p>
          <p>
            We have no control over the contents of those sites or resources.
          </p>
          <p>
            <strong>16. Changes to the terms</strong>
          </p>
          <p>
            We may revise these terms at any time by amending this page. We will
            use appropriate means, such as relevant announcements on our
            website, to inform you on such amendments. However, we ask you to
            check this page from time to time to take notice of any changes we
            made, as you will be subject to the terms and conditions in force at
            the time that you use our Sites. If you do not agree with the
            changes, you must stop using the Sites.
          </p>
          <p>
            <strong>17. Applicable Law</strong>
          </p>
          <p>
            If you are a consumer, please note that these terms of use, their
            subject matter and their formation, are governed by English law and
            you and we both agree that the courts of England and Wales will have
            non-exclusive jurisdiction, however nothing in this clause 17 shall
            prevent you from being able to bring a claim in the courts of your
            country of residence under the applicable laws of your country of
            residence in situations where your right to do so is mandatory under
            applicable local law.
          </p>
          <p>
            If you are a business, these terms of use, their subject matter and
            their formation (and any non-contractual disputes or claims) are
            governed by English law. We both agree to the non-exclusive
            jurisdiction of the courts of England and Wales.
          </p>
          <p>
            <strong>18. Contact us</strong>
          </p>
          <p>
            To contact us, please email{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
          </p>
        </Box>
      </Box>
    </GenericPage>
  );
}
